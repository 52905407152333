<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogTopMessage"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Top Message</span
              >
              <span class="headline" v-else>Edit Top Message</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTopMessage = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nội dung:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group
                        v-model="type_input"
                        row
                        class="mb-0 mt-0 pb-0"
                        dense
                      >
                        <v-radio label="Nội dung" :value="type.TEXT"></v-radio>
                        <v-radio label="Ảnh" :value="type.IMAGE"></v-radio>
                      </v-radio-group>
                      <Editor
                        v-if="type_input === 1"
                        v-model="content_input"
                      ></Editor>
                      <template v-if="type_input === 2">
                        <b-form-file
                          @change="onChange"
                          placeholder="Chọn tệp tin hoặc cancel để xóa"
                          accept="image/*"
                        ></b-form-file>
                        <template v-if="file_content_data != null">
                          <b-img
                            fluid
                            thumbnail
                            :rounded="true"
                            :src="file_content_data"
                          ></b-img>
                        </template>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Ảnh hiển thị trên mobile:</label
                      ></v-col
                    >
                    <v-col
                      cols="8"
                      class="p-0 m-0 ml-4"
                      id="topmess-mobile-url"
                    >
                      <b-form-file
                        @change="onChangeMobileUrl"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="mobile_url_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="mobile_url_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Due time (y-m-d h:m:i):</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                        class="ml-2"
                        v-model="due_time_input"
                        type="datetime"
                        value-type="format"
                        placeholder="chọn thời gian"
                      ></date-picker>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Active:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-switch
                        class="mt-0 pt-1 ml-2"
                        v-model="is_active"
                        :label="is_active ? 'Yes' : 'No'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Url:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        v-model="url_input"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Hiển thị đồng hồ đếm:</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-switch
                        class="mt-0 pt-1 ml-2"
                        v-model="show_timer_input"
                        :label="show_timer_input ? 'Có' : 'Không'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <!--                  <v-row class="mt-8">-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Áp dụng màn my course:</label></v-col>-->
                  <!--                    <v-col cols="8" class="p-0 m-0 ml-4">-->
                  <!--                      <v-switch class="mt-0 pt-1 ml-2" v-model="is_apply_my_course" :label="is_apply_my_course === true ? 'Yes' : 'No'"></v-switch>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <!--                  <v-row class="mt-8">-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Áp dụng màn home:</label></v-col>-->
                  <!--                    <v-col cols="8" class="p-0 m-0 ml-4">-->
                  <!--                      <v-switch class="mt-0 pt-1 ml-2" v-model="is_apply_home" :label="is_apply_home === true ? 'Yes' : 'No'"></v-switch>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <!--                  <v-row class="mt-8">-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Áp dụng less/ test:</label></v-col>-->
                  <!--                    <v-col cols="8" class="p-0 m-0 ml-4">-->
                  <!--                      <v-switch class="mt-0 pt-1 ml-2" v-model="is_apply_less_test" :label="is_apply_less_test === true ? 'Yes' : 'No'"></v-switch>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <!--                  <v-row class="mt-8">-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Áp dụng tất cả các chương trình (programme):</label></v-col>-->
                  <!--                    <v-col cols="8" class="p-0 m-0 ml-4">-->
                  <!--                      <v-switch class="mt-0 pt-1 ml-2" v-model="is_apply_all_programme" :label="is_apply_all_programme === true ? 'Yes' : 'No'"></v-switch>-->
                  <!--                      <v-autocomplete-->
                  <!--                          v-if="is_apply_all_programme === false"-->
                  <!--                          v-model="programme_ids"-->
                  <!--                          :items="programme_tags"-->
                  <!--                          filled-->
                  <!--                          chips-->
                  <!--                          color="blue-grey lighten-2"-->
                  <!--                          label=""-->
                  <!--                          item-text="name.vi"-->
                  <!--                          item-value="id"-->
                  <!--                          multiple-->
                  <!--                      >-->
                  <!--                      </v-autocomplete>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditTopMessage(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditTopMessage(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogTopMessage = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Editor from "@/components/ckeditor5/Editor";

export default {
  name: "TopMessageForm",
  components: {
    Editor,
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    top_message: {},
    programme_tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
      content_input: "",
      // programme_ids: [],
      // is_apply_all_programme: false,
      // is_apply_home: false,
      // is_apply_my_course: false,
      // is_apply_less_test: false,
      due_time_input: null,
      top_message_id: null,
      is_active: true,
      type_input: null,
      file_content_data: null,
      file_content_file: null,
      url_input: null,
      show_timer_input: true,
      type: {
        TEXT: 1,
        IMAGE: 2,
      },
      mobile_url_data: null,
      mobile_url_file: null,
    };
  },
  computed: {
    dialogTopMessage: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogTopMessage(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      this.setDefaultLabelAvatar();
      if (this.type_form === "add") {
        this.content_input = "";
        // this.programme_ids = [];
        // this.is_apply_all_programme = false;
        // this.is_apply_home = false;
        // this.is_apply_my_course = false;
        // this.is_apply_less_test = false;
        this.due_time_input = null;
        this.is_active = true;
        this.file_content_data = null;
        this.file_content_file = null;
        this.type_input = this.type.TEXT;
        this.show_timer_input = true;
        this.url_input = "";
        this.mobile_url_data = null;
        this.mobile_url_file = null;
      } else {
        let data = JSON.parse(JSON.stringify(this.top_message));
        this.top_message_id = data.id;
        this.content_input = data.content == null ? "" : data.content;
        // this.programme_ids = data.programmes.length <=0 ? [] : data.programmes.map((e) => e.id);
        // this.is_apply_all_programme = data.is_apply_all_programme === 1;
        // this.is_apply_home = data.is_apply_home === 1;
        // this.is_apply_my_course = data.is_apply_my_course === 1;
        // this.is_apply_less_test = data.is_apply_less_test === 1;
        this.due_time_input = this.$options.filters.convertTimestampToYMD_HMS(
          data.due_time
        );
        this.is_active = data.active === 1;
        this.type_input = data.type ? data.type : 1;
        this.file_content_data = data.content_file;
        this.file_content_file = null;
        this.show_timer_input = data.show_timer === 1;
        this.mobile_url_file = null;
        this.mobile_url_data = data.mobile_url;
        this.url_input = data.url ?? '';
      }
    },
    validateForm() {
      let flat = true;
      if (this.type_input === 1) {
        if (this.content_input === null || this.content_input === "") {
          this.$toasted.error("Hãy nhập nội dung !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      } else if (this.type_input === 2) {
        if (this.file_content_data === null || this.file_content_data === "") {
          this.$toasted.error("Hãy nhập file ảnh !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.due_time_input === null) {
        this.$toasted.error("Hãy nhập due time!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddOrEditTopMessage(type) {
      let validateForm = this.validateForm();
      if (!validateForm) {
        return;
      }
      let vm = this;

      let formData = new FormData();
      formData.append(
        "due_time",
        this.$options.filters.convertYMD_HMStoTimestamp(this.due_time_input)
      );
      // formData.append('is_apply_all_programme', this.is_apply_all_programme ? 1 : 0);
      // formData.append('is_apply_home', this.is_apply_home ? 1 : 0);
      // formData.append('is_apply_my_course', this.is_apply_my_course ? 1 : 0);
      // formData.append('is_apply_less_test', this.is_apply_less_test ? 1 : 0);
      // formData.append('programme_ids', this.is_apply_all_programme ? [] : this.programme_ids);
      formData.append("type", this.type_input);
      formData.append("active", this.is_active ? 1 : 0);
      formData.append("show_timer", this.show_timer_input ? 1 : 0);
      if (!(this.url_input === null || this.url_input === ""))
        formData.append("url", this.url_input);
      if (this.type_input === 1) {
        formData.append("content", this.content_input);
      } else if (this.type_input === 2) {
        if (this.file_content_file != null)
          formData.append("file_content", this.file_content_file);
      }
      if (this.mobile_url_file != null)
        formData.append("mobile_url_file", this.mobile_url_file);

      this.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/top-message", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogTopMessage = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post("prep-app/top-message/" + vm.top_message_id, formData)
          .then(function (res) {
            if (res.status === 202) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogTopMessage = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.file_content_data = null;
        vm.file_content_file = null;
        return;
      }
      vm.file_content_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.file_content_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onChangeMobileUrl(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.mobile_url_data = null;
        vm.mobile_url_file = null;
        return;
      }
      vm.mobile_url_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.mobile_url_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    setDefaultLabelAvatar() {
      let ele = document.getElementById("topmess-mobile-url");
      if (ele) {
        ele.firstChild.children[1].firstChild.textContent =
          "Chọn tệp tin hoặc cancel để xóa";
      }
    },
  },
};
</script>

<style scoped></style>
