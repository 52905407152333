<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogPositionWeb"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Cấu hình vị trí hiển thị trên web</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogPositionWeb = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <template v-for="(page, index_page) in list_web_page">
                    <v-row class="mt-4" :key="index_page">
                      <v-col cols="2" class="text-right p-0 m-0"
                        ><label class="text-h6">{{ page.label }}:</label></v-col
                      >
                      <v-col cols="8" class="p-0 m-0 ml-4">
                        <v-checkbox
                          v-model="page.checked"
                          color="cyan"
                          hide-details
                          class="mt-0 pt-1"
                          @click="selectAllItem(index_page)"
                        ></v-checkbox>
                        <template v-if="page.items.length > 0">
                          <v-row class="ml-8 mt-1 mb-1">
                            <template v-for="(item, index_item) in page.items">
                              <v-col cols="3" :key="index_item" class="m-1 p-0">
                                <v-checkbox
                                  v-model="item.checked"
                                  :label="item.name"
                                  color="cyan"
                                  hide-details
                                  class="mt-0 pt-0"
                                  @click="checkPageChecked(index_page)"
                                ></v-checkbox>
                              </v-col>
                            </template>
                          </v-row>
                        </template>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnSavePosition()"
            >
              Save
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogPositionWeb = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PositionWeb",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    model: {
      type: String,
      default: "",
    },
    list_web_page: {
      type: Array,
      default: () => [],
    },
    data_position_model: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
    };
  },
  computed: {
    dialogPositionWeb: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogPositionWeb(val) {
      if (val) {
        this.setPositionListWeb();
      } else {
        this.setDefaultCheckedForListWeb();
      }
    },
  },
  methods: {
    btnSavePosition() {
      let dataPage = [];
      for (let iPage = 0; iPage < this.list_web_page.length; iPage++) {
        if (this.list_web_page[iPage].checked) {
          dataPage.push(this.list_web_page[iPage]);
        }
      }

      let dataPosition = [];
      for (let iPage = 0; iPage < dataPage.length; iPage++) {
        if (dataPage[iPage].items.length > 0) {
          for (let iItem = 0; iItem < dataPage[iPage].items.length; iItem++) {
            if (dataPage[iPage].items[iItem].checked) {
              dataPosition.push({
                type: dataPage[iPage].value,
                value: dataPage[iPage].items[iItem].value,
                url: dataPage[iPage].items[iItem].url,
              });
            }
          }
        } else {
          dataPosition.push({
            type: dataPage[iPage].value,
          });
        }
      }

      this.$emit("submitPosition", dataPosition);
    },
    selectAllItem(page_item) {
      if (this.list_web_page[page_item].checked) {
        if (this.list_web_page[page_item].items.length > 0) {
          for (let i = 0; i < this.list_web_page[page_item].items.length; i++) {
            this.list_web_page[page_item].items[i].checked = true;
          }
        }
      } else {
        if (this.list_web_page[page_item].items.length > 0) {
          for (let i = 0; i < this.list_web_page[page_item].items.length; i++) {
            this.list_web_page[page_item].items[i].checked = false;
          }
        }
      }
    },
    setDefaultCheckedForListWeb() {
      this.list_web_page.forEach(function (page) {
        page.checked = false;
        if (page.items.length > 0) {
          page.items.forEach(function (item) {
            item.checked = false;
          });
        }
      });
    },
    setPositionListWeb() {
      // for mảng list page, xong rồi for mảng position
      for (let iPage = 0; iPage < this.list_web_page.length; iPage++) {
        for (
          let iPosition = 0;
          iPosition < this.data_position_model.length;
          iPosition++
        ) {
          //kiểm tra nếu 1 giá trị trong data list_page = 1 giá trị position thì checked là true
          if (
            this.list_web_page[iPage].value ===
            this.data_position_model[iPosition].type
          ) {
            this.list_web_page[iPage].checked = true;

            // kiểm tra và set position các item page có checked là true
            if (
              this.list_web_page[iPage].items.length > 0 &&
              this.data_position_model[iPosition].pages.length > 0
            ) {
              for (
                let iItemList = 0;
                iItemList < this.list_web_page[iPage].items.length;
                iItemList++
              ) {
                for (
                  let iPagePosition = 0;
                  iPagePosition <
                  this.data_position_model[iPosition].pages.length;
                  iPagePosition++
                ) {
                  if (
                    this.list_web_page[iPage].items[iItemList].value ===
                    this.data_position_model[iPosition].pages[iPagePosition]
                  ) {
                    this.list_web_page[iPage].items[iItemList].checked = true;
                  }
                }
              }
            }
          }
        }
      }
    },
    checkPageChecked(indexPage) {
      if (!this.list_web_page[indexPage].checked) {
        this.list_web_page[indexPage].checked = true;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
