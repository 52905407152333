<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí top message</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogTopMessage"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm top message</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nội dung</th>
                    <!--                  <th scope="col">Hiện thị ở home</th>-->
                    <!--                  <th scope="col">Hiện thị ở my course</th>-->
                    <!--                  <th scope="col">Hiện thị ở less/test</th>-->
                    <th scope="col">Type</th>
                    <th scope="col">Hiện thị đếm ngược</th>
                    <th scope="col">Active</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(top_message, i) in top_messages">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <template v-if="top_message.type === 1">
                          <p
                            style="
                              max-width: max-content;
                              max-height: 300px;
                              overflow: hidden;
                            "
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            v-html="top_message.content"
                          ></p>
                        </template>
                        <template v-if="top_message.type === 2">
                          <v-img
                            v-if="top_message.content_file"
                            :src="top_message.content_file"
                            max-width="100"
                            max-height="70"
                            class="rounded-lg"
                          ></v-img>
                        </template>
                      </td>
                      <!--                    <td>-->
                      <!--                      <p class="text-dark-75 font-weight-bolder d-block font-size-lg">{{top_message.is_apply_home === 1 ? "Có" : "Không"}}</p>-->
                      <!--                    </td>-->
                      <!--                    <td>-->
                      <!--                      <p class="text-dark-75 font-weight-bolder d-block font-size-lg">{{top_message.is_apply_my_course === 1 ? "Có" : "Không"}}</p>-->
                      <!--                    </td>-->
                      <!--                    <td>-->
                      <!--                      <p class="text-dark-75 font-weight-bolder d-block font-size-lg">{{top_message.is_apply_less_test === 1 ? "Có" : "Không"}}</p>-->
                      <!--                    </td>-->
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="top_message.type === 1"
                        >
                          Văn bản
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="top_message.type === 2"
                        >
                          Ảnh
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ top_message.show_timer === 1 ? "Có" : "Không" }}
                        </p>
                      </td>
                      <td>
                        <span
                          style="width: 137px"
                          v-if="top_message.active === 0"
                          ><span
                            class="label label-warning label-dot mr-2"
                          ></span
                          ><span class="font-weight-bold text-warning"
                            >Không</span
                          ></span
                        >
                        <span
                          style="width: 137px"
                          v-if="top_message.active === 1"
                          ><span
                            class="label label-primary label-dot mr-2"
                          ></span
                          ><span class="font-weight-bold text-primary"
                            >Có</span
                          ></span
                        >
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editTopMessage(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDiaLogPosition(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-crosshairs</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Position</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteTopMessage(top_message.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <TopMessageForm
      :show_dialog="dialogTopMessage"
      :type_form="type_form"
      :programme_tags="programme_tags"
      :top_message="top_message"
      @setDialog="(e) => (this.dialogTopMessage = e)"
      @resetData="getAllTopMessage()"
    />

    <PositionWeb
      :show_dialog="dialogPositionWeb"
      :list_web_page="list_web_page"
      :data_position_model="data_position_top_mess"
      @setDialog="(e) => (this.dialogPositionWeb = e)"
      @submitPosition="(e) => this.submitPosition(e)"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import TopMessageForm from "./modal/TopMessageForm";
import PositionWeb from "./modal/PositionWeb";
export default {
  name: "TopMessageList",
  components: {
    PositionWeb,
    TopMessageForm,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      top_messages: [],
      dialogTopMessage: false,
      type_form: null,
      programme_tags: [],
      top_message: {},
      dialogPositionWeb: false,
      list_web_page: [],
      top_message_id: null,
      data_position_top_mess: [],
    };
  },
  created() {
    this.getAllTopMessage();
    // this.getAllProgramme();
    this.listWebPage();
  },
  methods: {
    async getAllProgramme() {
      let vm = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.programme_tags = response.data;
        }
      });
    },
    async getAllTopMessage() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/top-message");
        if (res.status === 200) {
          vm.top_messages = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogTopMessage() {
      this.type_form = "add";
      this.dialogTopMessage = true;
    },

    editTopMessage(index) {
      this.top_message = this.top_messages[index];
      this.type_form = "update";
      this.dialogTopMessage = true;
    },
    btnDeleteTopMessage(top_message_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/top-message/" + top_message_id)
            .then(function (res) {
              if (res.status === 202) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllTopMessage();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    async listWebPage() {
      let vm = this;
      await ApiService.get("prep-app/list-web-page?").then(function (response) {
        if (response.status === 200) {
          vm.list_web_page = response.data.map(function (page) {
            page.checked = false;
            if (page.items.length > 0) {
              page.items = page.items.map(function (item) {
                item.checked = false;
                return item;
              });
            }
            return page;
          });
        }
      });
    },
    async openDiaLogPosition(index_top_mess) {
      let vm = this;
      this.top_message_id = this.top_messages[index_top_mess].id;

      this.is_call_api = true;
      await ApiService.get(
        "prep-app/top-message/" + this.top_message_id + "/get-position-web"
      ).then(function (res) {
        if (res.status === 200) {
          vm.is_call_api = false;
          vm.data_position_top_mess = res.data;
          vm.dialogPositionWeb = true;
        }
      });
    },
    submitPosition(dataPosition) {
      let vm = this;
      let data = {
        position: dataPosition,
      };
      this.is_call_api = true;
      ApiService.post(
        "prep-app/top-message/" + this.top_message_id + "/save-position-web?",
        data
      ).then(function (res) {
        if (res.status === 200) {
          vm.$toasted.success("Lưu position thành công !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          vm.is_call_api = false;
          vm.dialogPositionWeb = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
